import { useDispatch } from "react-redux";
import { Segmented } from "antd";
import { Tabs as TabsItems } from "antd";

const Tabs = ({ tabs, setTab, defaultTab, type }) => {
  const dispatch = useDispatch();
  return (
    <div className="view_tabs">
      {type === "details" ? (
        <TabsItems
          tabBarGutter={40}
          defaultActiveKey={defaultTab}
          items={tabs}
          onChange={(e) => dispatch(setTab(e))}
        />
      ) : (
        <Segmented
          activeClassName="active-segment"
          defaultValue={defaultTab}
          options={tabs}
          onChange={(e) => dispatch(setTab(e))}
        />
      )}
    </div>
  );
};

export default Tabs;
