import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "..";
import axiosInstance from "../../utils/axios";
import publicAxios from "../../utils/axios";
import axios from "../../utils/axios";
import { Config } from "../../config";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const auth_request = async (state) => {
  const stateToken = localStorage.getItem("state");
  if (stateToken) return stateToken;
  const newStateToken = uuidv4();
  localStorage.setItem("state", newStateToken);
  return newStateToken;
};

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  error: null,
  message: null,
  state: null,
  stateToken: null
};

export const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    // axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    const accessToken = localStorage.getItem("gomydesk_token");
    fetch(Config.REACT_APP_API_URL + "auth/operator/logout", {
      method: "post",
      headers: new Headers({
        "x-session-token": accessToken
      }),
      credentials: "include"
    });
  }
};

export const login = createAsyncThunk("auth/login", async (query) => {
  const { username, password } = query;
  let data;
  try {
    const stateToken = await auth_request();
    const response = await axiosInstance.post(`auth/operator/login`, {
      username,
      password,
      state: stateToken
    });
    data = await response.data;
    const resState = response.data.state;
    if (stateToken !== resState) throw new Error("State token was malformed during request flow!");

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});
//to remove
export const oauthLogin = createAsyncThunk("auth/oauthLogin", async (query) => {
  const { access_token } = query;
  let data;
  try {
    const stateToken = await auth_request();
    const response = await axiosInstance.post(`/oidc/login`, {
      code: access_token,
      state: stateToken
    });
    data = await response.data;
    const resState = response.data.state;
    if (stateToken !== resState) throw new Error("State token was malformed during request flow!");
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const refreshToken = createAsyncThunk("auth/refresh", async (query) => {
  let data;
  try {
    const response = await publicAxios.get(`auth/operator/refresh_token`);
    data = await response.data;
    const newAccessToken = data.token;
    localStorage.setItem("token", newAccessToken);
    axiosInstance.defaults.headers.common["x-session-token"] = newAccessToken;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    store.dispatch(logout());
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgot-password", async (query) => {
  const { email } = query;
  let data;
  try {
    const response = await axios.post(`auth/forgot-password`, { email });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const resetPassword = createAsyncThunk("auth/reset-password", async (query) => {
  const { email, resetCode, password, passwordConfirm } = query;
  let data;
  try {
    const response = await axios.post(`auth/reset-password`, {
      email,
      resetCode,
      password,
      passwordConfirm
    });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialised = true;
      state.user = user;
    },
    restore: (state) => {
      state.error = null;
      state.message = null;
    },
    logout: (state) => {
      setSession(null);
      state.isAuthenticated = false;
      state.user = null;
      // window.location.href = "/auth/login";
    }
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [login.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [login.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [oauthLogin.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [oauthLogin.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [oauthLogin.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [forgotPassword.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [forgotPassword.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [resetPassword.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { initialise, logout, restore } = authSlice.actions;

export default authSlice.reducer;
