export const IMAGE_GROUP_FIELDS = [
  {
    name: "name",
    type: "string",
    placeholder: "Enter the field name",
    label: "Image Group Name*"
  },
  {
    name: "description",
    type: "string",
    placeholder: "Write a brief description...",
    label: "Description*"
  }
];

export const IMAGE_FIELDS = [
  {
    name: "dockerImage",
    type: "string",
    placeholder: "Enter your docker image",
    label: "Docker Image*"
  },
  {
    name: "name",
    type: "string",
    placeholder: "Enter your image name",
    label: "Image Name*"
  },
  {
    name: "description",
    type: "string",
    placeholder: "Write a brief description...",
    label: "Description*"
  }
];

export const CONFIG_FIELDS = [
  { name: "cores", label: "Number of Cpu (m)", withDefaultVal: true },
  { name: "cards", label: "Number of GPU (m)", withDefaultVal: true },
  { name: "ram", label: "Ram Size (M)", withDefaultVal: true },
  { name: "disk", label: "Disk Size (G)", withDefaultVal: true }
];
