import { CloudUploadOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";

function InputUpload({ field, formik }) {
  const handleInputChange = (e) => {
    formik.setFieldValue(field?.name, {
      file: e.target?.files[0],
      upload: true
    });
  };
  const uploadFileHandler = () => {
    const inputElement = document.createElement("input");
    inputElement.setAttribute("type", "file");
    inputElement.click();
    if (inputElement) inputElement.addEventListener("change", handleInputChange);
  };

  return (
    <span className="s_input">
      <label htmlFor={field?.name}>{field?.label}</label>
      <div className="upload-container">
        <div className="upload-icon" onClick={uploadFileHandler}>
          <CloudUploadOutlined />
        </div>
        <Input
          id={field?.name}
          name={field?.name}
          type={field?.type}
          className="input_antd"
          placeholder={field?.placeholder}
          value={formik.values[field?.name]?.file?.name || formik.values[field?.name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          status={formik.errors[field?.name] && formik.touched[field?.name] ? "error" : null}
        />
      </div>
      <p className="error_txt">{formik.touched[field?.name] && formik.errors[field.name]}</p>
    </span>
  );
}

export default InputUpload;
