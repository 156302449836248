import { Select, Tag } from "antd";

function InputSelect({
  formik,
  options,
  defaultOptions,
  onChange,
  label,
  field,
  value,
  onDropdownVisibleChange,
  placeholder,
  single,
  disabled,
  status
}) {
  return (
    <span className="select">
      <label htmlFor={label}>{label}</label>
      <Select
        status={status ? status : (formik.errors[field] && formik.touched[field] ? "error" : null)}
        mode={single ? null : "multiple"}
        showArrow
        tagRender={Tag}
        style={{
          width: "100%"
        }}
        onChange={onChange}
        placeholder={placeholder || "Select"}
        options={options}
        defaultValue={defaultOptions}
        value={value}
        onDropdownVisibleChange={onDropdownVisibleChange}
        allowClear
        showSearch
        disabled={disabled}
      ></Select>
    </span>
  );
}

export default InputSelect;
