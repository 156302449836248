import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { MEMBERS } from "../../constants";

const initialiseMembers = (users) => {
  return users.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  pendingUsers: null,
  searchItems: [],
  meta: { totalPages: 1 },
  user: {},
  roles: [],
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

//get users
export const getPendingUsers = createAsyncThunk("api/pending_users", async ({ query }) => {
  let data;
  // let str = page && limit && search ? `?search=${search}page=${page}&limit=${limit}` : "";
  try {
    const response = await axios.get(`/users/invites/pending${query}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const sendInvitation = createAsyncThunk("api/send-invitation", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/users/invite`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});

// delete user
export const deleteUser = createAsyncThunk("api/delete-user", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getPendingUsers({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// get one user
export const getUser = createAsyncThunk("api/get-user", async (id) => {
  let data;
  try {
    const response = await axios.get(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const pendingSlice = createSlice({
  name: "pending",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.pendingUsers = current(state).pendingUsers.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.pendingUsers = current(state).pendingUsers.map((member) => {
        if (member.username === action.payload.id)
          return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setUsers: (state, action) => {
      state.pendingUsers = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    //get users
    [getPendingUsers.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getPendingUsers.fulfilled]: (state, action) => {
      const users = action.payload.invitations;
      const newData = initialiseMembers(users);
      state.pendingUsers = newData;
      state.searchItems = newData;

      state.meta = action.payload.meta;

      state.state = "success";
    },
    [getPendingUsers.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },

    //get user
    [getUser.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getUser.fulfilled]: (state, action) => {
      const user = action.payload;
      state.state = "success";
      state.user = user;
    },
    [getUser.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setUsers, setSearchString } = pendingSlice.actions;

export default pendingSlice.reducer;
