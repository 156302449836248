import React from "react";
import { InputNumber as Input } from "antd";

function InputNumber({ field, ...props }) {
  return (
    <span className="s_input">
      {field?.label && <label htmlFor={field?.name}>{field.label}</label>}
      <Input {...props} name={field?.name} />
    </span>
  );
}

export default InputNumber;
