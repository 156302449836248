import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { store } from "..";
import { initialise } from "./authSlice";
import { getUsersPerOrg } from "./usersPerOrg";
import { handleQuery } from "../../helpers/searchQuery";

const initialiseMembers = (users) => {
  return users.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  members: [],
  searchItems: [],
  meta: {},
  user: {},
  roles: [],
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

//get users
export const getUsers = createAsyncThunk("api/users", async ({ query, orgId, userType }) => {
  let data;
  let searchStr = orgId ? `&search=${orgId}` : "";
  let strType = userType ? `&userType=${userType}` : "";
  try {
    const response = await axios.get(`/users${query}${searchStr}${strType}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createUser = createAsyncThunk("api/crate-user", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/users`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      if (values?.orgId) {
        thunkApi.dispatch(
          getUsersPerOrg({
            query: handleQuery({ page: 1, limit: 100 }),
            orgId: values.orgId,
            userType: "1"
          })
        );
      } else {
        thunkApi.dispatch(getUsers({ query: "?page=1&limit=8", userType: "0" }));
      }
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});
// delete user
export const deleteUser = createAsyncThunk("api/delete-user", async ({ id, orgId }, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      if (orgId) {
        thunkApi.dispatch(
          getUsersPerOrg({
            query: handleQuery({ page: 1, limit: 100 }),
            orgId: orgId,
            userType: "1"
          })
        );
      } else {
        thunkApi.dispatch(getUsers({ query: "?page=1&limit=8", userType: "0" }));
      }
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// get one user
export const getUser = createAsyncThunk("api/get-user", async (id) => {
  let data;
  try {
    const response = await axios.get(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// edit user
export const updateUser = createAsyncThunk("api/edit-user", async (query, thunkApi) => {
  let data;
  let { values, id, orgId } = query;

  let orgQuery = orgId ? `?orgId=${orgId}` : "";
  try {
    const response = await axios.put(`/users/${id}${orgQuery}`, { ...values });
    data = await response.data;

    const { user } = store.getState().auth;
    if (user?.name === id) {
      const response = await axios.get("profile/whoami");
      const user = response.data;
      store.dispatch(initialise({ isAuthenticated: true, user }));
    }

    if (response.status === 200) {
      // if (values?.orgId || orgId) {
      thunkApi.dispatch(
        getUsersPerOrg({
          query: handleQuery({ page: 1, limit: 8 }),
          orgId: orgId,
          userType: "1"
        })
      );
      // } else {
      thunkApi.dispatch(getUsers({ query: "?page=1&limit=8", userType: "0" }));
      // }
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const updateUserPassword = createAsyncThunk(
  "api/edit-user-password",
  async (query, thunkApi) => {
    let data;
    let { password, id } = query;
    try {
      const response = await axios.put(`/users/${id}/password`, { password });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);
//activate / deactivate multiple users
export const changeUserStatus = createAsyncThunk("api/activate-users", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.put(`/admin/users/status`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUsers({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const updateMePassword = createAsyncThunk(
  "api/edit-me-password",
  async (query, thunkApi) => {
    let data;
    let { password, oldPassword } = query;

    try {
      const response = await axios.put(`/profile/password`, { oldPassword, password });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.members = current(state).members.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.members = current(state).members.map((member) => {
        if (member.username === action.payload.id)
          return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setUsers: (state, action) => {
      state.members = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    //get users
    [getUsers.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      const users = action.payload.users;
      const newData = initialiseMembers(users);
      state.members = newData;
      state.searchItems = newData;

      state.meta = action.payload.meta;

      state.state = "success";
    },
    [getUsers.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //get roles
    // [getRoles.pending]: (state) => {
    //   state.error = null;
    // },
    // [getRoles.fulfilled]: (state, action) => {
    //   const roles = action.payload;
    //   state.roles = roles;
    // },
    // [getRoles.rejected]: (state, action) => {
    //   state.error = action.error.message;
    // },
    //create user
    [createUser.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createUser.fulfilled]: (state, action) => {
      const user = action.payload;
      state.state = "success";
    },
    [createUser.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //get user
    [getUser.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getUser.fulfilled]: (state, action) => {
      const user = action.payload;
      state.state = "success";
      state.user = user;
    },
    [getUser.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setUsers, setSearchString } = memberSlice.actions;

export default memberSlice.reducer;
