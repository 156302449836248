import React from "react";

const styles = {
  connected: {
    backgroundColor: "#D1FADF",
    color: "#027A48"
  },
  fail: { backgroundColor: "#FEE4E2", color: "#F04438" },
  pending: { backgroundColor: "#FFDDB1", color: "#F79009" }
};

function Status({ type, label }) {
  const style = styles[type];

  return (
    <div className="status_column">
      <div style={style}>
        <p style={{ backgroundColor: style?.color }}></p> {label}
      </div>
    </div>
  );
}

export default Status;
