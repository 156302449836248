import { nanoid } from "nanoid";
import avatar from "../assets/images/img1.svg";

const MEMBERS = [
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: false
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: false
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: false
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  },
  {
    id: nanoid(10),
    avatar: null,
    userName: "Foulen Fouleni",
    email: "user@gmail.com",
    phone: "22951753",
    password: null,
    role: null,
    isActive: true
  }
];

const USER_GROUPS = [
  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "Group 2",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "Group 1",
    imageGroup: "Designer Group",
    date: "26/10/2022"
  }
];

const IMAGE_GROUPS = [
  {
    name: "IU/ux design1",
    status: true,
    images: [
      {
        name: "hae",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae3",
        image: ""
      }
    ],
    usersCount: 14,
    usersGroupCount: 4,
    date: "26/10/2022"
  },
  {
    name: "IU/ux design2",
    status: true,
    images: [
      {
        name: "hae",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae3",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae3",
        image: ""
      }
    ],
    usersCount: 14,
    usersGroupCount: 4,
    date: "26/10/2022"
  },
  {
    name: "IU/ux design3",
    status: true,
    images: [
      {
        name: "hae",
        image: ""
      },
      {
        name: "hae",
        image: ""
      },
      {
        name: "hae2",
        image: ""
      },
      {
        name: "hae3",
        image: ""
      }
    ],
    usersCount: 14,
    usersGroupCount: 4,
    date: "26/10/2022"
  }
];

const IMAGES = [
  {
    kind: "Template",
    apiVersion: "desktops.gomydesk.io/v1",
    metadata: {
      name: "ubunto-test",
      creationTimestamp: "2023-02-22T16:20:19Z",
      labels: {
        app: "desktop",
        kvdiapp: "ubuntu",
        version: "v0.1.9"
      }
    },
    spec: {
      desktop: {
        image: "ghcr.io/gomydesk/ubuntu-xfce4:latest",
        imagePullPolicy: "IfNotPresent",
        resources: {
          limits: {
            cpu: "500m",
            "ephemeral-storage": "500M",
            memory: "500M"
          }
        }
      },
      proxy: {
        image: "ghcr.io/gomydesk/proxy:latest",
        allowFileTransfer: true,
        resources: {
          requests: {
            cpu: "100m",
            memory: "50M"
          }
        }
      },
      tags: {
        applications: "dind3",
        desktop: "xfce4",
        os: "ubuntu",
        type: "shared"
      }
    }
  }
];

const INVOICES = [
  {
    id: nanoid(10),
    avatar: avatar,
    name: "Invoice #002 - Dec 2020",
    imageGroup: "Designer Group",
    billingDate: "Dec 1, 2022 ",
    amount: "10",
    users: 10,
    user: {
      name: "foulen",
      email: "foulen@gmail.com",
      avatar: avatar
    },
    status: "paid",
    pdf: ""
  },

  {
    id: nanoid(10),
    avatar: avatar,
    name: "Invoice #002 - Dec 2022",
    imageGroup: "Designer Group",
    billingDate: "Dec 1, 2022 ",
    amount: "10",
    users: 10,
    user: {
      name: "foulen",
      email: "foulen@gmail.com",
      avatar: avatar
    },
    status: "pending",
    pdf: ""
  },
  {
    id: nanoid(10),
    avatar: avatar,
    name: "Invoice #002 - Dec 2023",
    imageGroup: "Designer Group",
    billingDate: "Dec 1, 2022 ",
    amount: "10",
    users: 10,
    user: {
      name: "foulen",
      email: "foulen@gmail.com",
      avatar: avatar
    },
    status: "unpaid ",
    pdf: ""
  }
];

//orgs
const ORGS = [
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 1",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 1",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 2",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 3",
    date: "26/10/2022"
  },

  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 6",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 9",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 4",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 4",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 4",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 4",
    date: "26/10/2022"
  },
  {
    id: nanoid(10),
    avatar: null,
    name: "ORGS 4",
    date: "26/10/2022"
  }
];

//offers

const OFFERS = [
  {
    id: nanoid(10),
    name: "Community",
    description: "For individuals trialing Gitpod Cloud.",
    price: 0,
    tags: [
      "50 hours per month for free",
      "Trial, Non-Profit, Personal Use",
      "All Features in Enterprise",
      "Community Support"
    ],
    paymentMethod: "Pay-as-you-go",
    type: "Free"
  },
  {
    id: nanoid(10),
    name: "Professional",
    description: "Hosted and managed by GoMyDesk.",
    price: 5,
    tags: [
      "Browser Isolation",
      "Desktop as a Service",
      "Application Streaming",
      "Single Sign-On,LDAP",
      "Web Traffic Filtering",
      "Customized Images",
      "Persistent Profiles and File Shares",
      " Next Business Day Support"
    ],
    paymentMethod: "Pay-as-you-go ",
    type: "Pay-as-you-go"
  },
  {
    id: nanoid(10),
    name: "Enterprise",
    description: "Hosted by you, managed by Gitpod",
    price: 10,
    tags: [
      "All Features in Professional",
      "Private Cloud Auto-Scaling",
      "Developer API",
      "Custom Branding",
      "Shared Sessions",
      "Enterprise Logging",
      "Same Business Day Support"
    ],
    paymentMethod: "Pay-as-you-go ",
    type: "Pay-as-you-go"
  }
];

export { MEMBERS, USER_GROUPS, IMAGE_GROUPS, IMAGES, INVOICES, ORGS, OFFERS };
