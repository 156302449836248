import { FieldArray, FormikProvider, useFormik } from "formik";
import { CONFIG_FIELDS, IMAGE_FIELDS as fields } from "./../../constants/fields";
import React, { useEffect, useState } from "react";
import { InputTextArea, InputText } from "../../../../components/InputFields";
import { closeModal } from "../../../../data/slices/modals";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { message, Select, Switch } from "antd";
import { createTemplate, updateImage } from "../../../../data/slices/templates";
import InputUpload from "../../../../components/InputFields/InputUpload/InputUpload";
import { getCategoriesTemplates } from "../../../../data/slices/categoriesTemplates";
import { Grid } from "@mui/material";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";

const reformulateResponse = (items) => {
  return items?.map((item) => {
    return {
      label: item,
      value: item
    };
  });
};

const isImgUrl = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
};

const setDefaultConfig = (formik, value, name) => {
  if (formik && formik.values)
    formik.values[name] = formik?.values?.[name]?.map((item) =>
      item?.value === value ? { ...item, isActive: true } : { ...item, isActive: false }
    );
};

const getValues = (list) => {
  return list?.map((item) => item?.value.toString());
};
const getDefaultValue = (list) => {
  return list?.find((item) => item?.isActive)?.value.toString();
};

const formulateResonse = (res) => {
  return res?.allowedValues?.map((item) => ({
    value: parseInt(item),
    isActive: res?.defaultValue === item
  }));
};

const Template = ({ dockerImage, name, SkuConfig, ...rest }) => {
  return {
    name,
    image: dockerImage,
    SkuConfig,
    ...rest
  };
};

const imageFormValidation = Yup.object().shape({
  dockerImage: Yup.string().min(2, "Too Short!").max(256, "Too Long!").required("Required"),
  name: Yup.string().min(2, "Too Short!").max(70, "Too Long!").required("Required"),
  ram: Yup.array()
    .of(
      Yup.object({
        value: Yup.number("is not a number").integer().required("required"),
        isActive: Yup.boolean()
      })
    )
    .min(1, "ram is Required")
    .test("at-least-one-active", "Select a default value", (value) => {
      return value?.some((item) => item?.isActive === true);
    })
    .required(" ram is Required "),
  cores: Yup.array()
    .of(
      Yup.object({
        value: Yup.number("is not a number").integer().required("cpu value cannot be empty"),
        isActive: Yup.boolean()
      })
    )
    .min(1, "cpu is required")
    .test("at-least-one-active", "Select a default value", (value) => {
      return value?.some((item) => item?.isActive === true);
    })
    .required(" cpu is required "),
  //gpu
  cards: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number()
          .integer("gpu value must be an integer")
          .min(0, "gpu value cannot be negative")
          .notRequired(), // GPU value is not required
        isActive: Yup.boolean()
      })
    )
    .test("at-least-one-active", "Select a default value", function (value) {
      const atLeastOneActive = value.some((item) => item?.isActive === true);
      const hasValues = value.some((item) => item?.value !== undefined);

      // If there are GPU values, ensure at least one is active and return the test result
      if (hasValues) {
        return atLeastOneActive;
      } else {
        // If no GPU values are added, the default value is not required
        return true;
      }
    }),
  disk: Yup.array()
    .of(
      Yup.object({
        value: Yup.number("is not a number").integer().required("required"),
        isActive: Yup.boolean()
      })
    )
    .min(1, "storage is required ")
    .test("at-least-one-active", "Select a default value", (value) => {
      return value?.some((item) => item?.isActive === true);
    })
    .required(" storage is required "),
  categories: Yup.array()
    .of(Yup.string().trim().min(1, "category validation fail"))
    .min(1, "category is required ")
    .required("category is required "),
  description: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
  thumbnail: Yup.lazy((value) => {
    return typeof value === "object"
      ? Yup.object()
          .test({
            message: "Is not a valid file",
            test: (file) => file?.file?.type?.split("/")[0] === "image"
          })
          .required("Required field")
          .typeError("Required field")
      : Yup.string()
          .test({
            message: "Is not a valid url",
            test: (file) => isImgUrl(file)
          })
          .required("Required field");
  })
});

function ImageForm({ isEdit, id, image }) {
  const [status, setStatus] = useState(!!image?.desktop?.allowRoot);
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    if (categories?.length === 0) dispatch(getCategoriesTemplates());
  }, []);

  const createImageHandler = (values, fileURL) => {
    values = {
      ...values,
      dockerImage: values?.dockerImage?.trim(),
      allowRoot: status,
      SkuConfig: {
        ram: {
          allowedValues: getValues(values?.ram),
          defaultValue: getDefaultValue(values?.ram) || values?.ram[0]?.value
        },
        cpu: {
          allowedValues: getValues(values?.cores) || [],
          defaultValue: getDefaultValue(values?.cores) || values?.cores[0]?.value
        },
        gpu: {
          allowedValues: getValues(values?.cards),
          defaultValue: getDefaultValue(values?.cards) || values?.cards[0]?.value || "0"
        },
        storage: {
          allowedValues: getValues(values?.disk),
          defaultValue: getDefaultValue(values?.disk) || values?.disk[0]?.value
        }
      },
      imageIconUrl: fileURL
    };

    delete values.cores;
    delete values.cards;
    delete values.ram;
    delete values.disk;
    delete values.thumbnail;
    if (!isEdit) {
      dispatch(createTemplate(Template(values)))
        .unwrap()
        .then((res) => {
          message.success(`Image created successfully.`);
          dispatch(closeModal("image-modal"));
        })
        .catch((err) => {
          message.error(err.message || "something went wrong");
        });
    } else {
      dispatch(updateImage({ values: Template(values), id }))
        .unwrap()
        .then(() => {
          message.success(`Image updated successfully.`);
          dispatch(closeModal("image-modal"));
        })
        .catch((err) => {
          message.error(err.message || "something went wrong");
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      dockerImage: isEdit ? image?.desktop?.image : "",
      name: isEdit ? image?.displayName : "",
      thumbnail: isEdit ? image?.imageIconUrl : "",
      description: isEdit ? image?.description : "",
      cores: isEdit ? formulateResonse(image?.skuConfig?.cpu) : [],
      cards: isEdit ? formulateResonse(image?.skuConfig?.gpu) : [],

      ram: isEdit ? formulateResonse(image?.skuConfig?.ram) : [],
      disk: isEdit ? formulateResonse(image?.skuConfig?.storage) : [],
      categories: isEdit ? image?.categories : [],
      allowRoot: false
    },
    validationSchema: imageFormValidation,
    onSubmit: (values) => {
      values?.cores?.sort((a, b) => a.value - b.value);
      values?.cards?.sort((a, b) => a.value - b.value);
      values?.ram?.sort((a, b) => a.value - b.value);
      values?.disk?.sort((a, b) => a.value - b.value);

      const file = values?.thumbnail || values?.thumbnail?.file;
      if (file?.upload) {
        const formData = new FormData();
        formData.append("file", file?.file);
        formData.append("upload_preset", "ph4slroc");

        fetch("https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload", {
          method: "POST",
          body: formData
        })
          .then((response) => response.json())
          .then((data) => {
            createImageHandler(values, data?.secure_url);
          })
          .catch((error) => {
            console.error(error);
          });
      } else createImageHandler(values, file);
    }
  });

  const handleChangeCategories = (value) => {
    console.log(value);
    if (formik && formik?.values) formik.values.categories = value;
  };

  return (
    <div className="image_form form">
      <FormikProvider value={formik}>
        <form action="" onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <InputText
                formik={formik}
                field={{
                  name: "dockerImage",
                  type: "string",
                  placeholder: "Enter your docker image",
                  label: "Docker Image*"
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputText
                formik={formik}
                field={{
                  name: "name",
                  type: "string",
                  placeholder: "Enter your image name",
                  label: "Image Name*"
                }}
              />
            </Grid>
          </Grid>

          <InputTextArea
            formik={formik}
            field={{
              name: "description",
              type: "string",
              placeholder: "Write a brief description...",
              label: "Description*"
            }}
          />

          <div className="input-upload">
            <InputUpload
              formik={formik}
              field={{
                name: "thumbnail",
                type: "string",
                placeholder: "Enter your logo or thumbnail URL",
                label: "Logo/ Thumbnail URL"
              }}
            />
          </div>
          <div className="categories">
            <label>categories*</label>
            <Select
              showArrow
              mode="tags"
              size="large"
              placeholder="Enter Your Categories"
              defaultValue={formik.values.categories || []}
              onChange={handleChangeCategories}
              style={{ width: "100%" }}
              options={reformulateResponse(categories)}
              className={
                formik.touched.categories && formik?.errors.categories ? "categories-error" : ""
              }
            />
            <p className="error_txt">{formik.touched["categories"] && formik?.errors.categories}</p>
          </div>
          {CONFIG_FIELDS?.map((field, i) => (
            <div key={i} className="dynamic-form-block">
              <label>{field?.label}</label>
              <div className="image_form-list">
                <FieldArray
                  name={field?.name}
                  render={(arrayHelpers) => (
                    <div className="image_form-list-container">
                      <div className="dynamic-input-container">
                        {formik.values?.[field?.name]?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="input-container">
                                <div className="dynamic-input-menu">
                                  <CloseIcon onClick={() => arrayHelpers.remove(index)} />
                                </div>
                                <InputText
                                  field={{
                                    name: `${field?.name}.${index}.value`,
                                    type: "number",
                                    placeholder: field?.name,
                                    defaultValue: 0
                                  }}
                                  value={formik.values?.[field?.name][index]?.value}
                                  formik={formik}
                                />
                              </div>
                            </div>
                          );
                        })}

                        <div
                          className="plus-btn-container"
                          onClick={() => arrayHelpers.push({ value: "0", isActive: false })}
                        >
                          <PlusIcon />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="s_input image_form-config-default">
                <label>Default {field?.name}</label>
                <Select
                  className="image_form-select"
                  style={{ width: "100%" }}
                  placeholder="Select Default Value"
                  defaultValue={formik.values?.[field?.name]?.find((item) => item?.isActive)}
                  onChange={(value) => setDefaultConfig(formik, value, field?.name)}
                  options={formik?.values?.[field?.name]
                    ?.map((item) => ({
                      label: item?.value,
                      value: item?.value
                    }))
                    .filter(({ label }) => !!label)}
                />
              </div>
              <p className="error_txt">
                {formik?.errors?.[field?.name] && formik?.touched?.[field?.name]
                  ? Array.isArray(formik.errors[field.name])
                    ? formik.errors[field.name].map((error, index) => (
                        <span key={index}>{error.value}</span>
                      ))
                    : formik.errors[field.name]
                  : null}
              </p>
            </div>
          ))}

          <div className="form__switchBox">
            <Switch defaultChecked={status} onChange={(e) => setStatus(e)} />
            <p>{!status ? "Prevent" : "Allow"} Root</p>
          </div>
          <div className="action_form">
            <button
              type="reset"
              onClick={() => {
                dispatch(closeModal("image-modal"));
                formik.setValues({});
              }}
              className="dashboard-cancel-btn"
            >
              Cancel
            </button>
            <button type="submit" className="dashboard-confirm-btn">
              {isEdit ? "Update Image" : "Submit"}
            </button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
}

export default ImageForm;
