import { Input } from "antd";

function InputText(props) {
  const { field, formik, value, min, status, pattern, ...other } = props;
  const InputComponent =
    field?.type === "password"
      ? Input.Password
      : field?.type === "textarea"
      ? Input.TextArea
      : Input;
  return (
    <span className="s_input">
      <label htmlFor={field?.name}>{field?.label}</label>
      <InputComponent
        // onKeyDown={(e) => {
        //   console.log(e);
        //   if (
        //     field?.type === "number" &&
        //     !["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(e.key) &&
        //     e.key !== "Backspace"
        //   ) {
        //     e.preventDefault();
        //   }
        // }}
        id={field?.name}
        name={field?.name}
        type={field?.type}
        className="input_antd"
        placeholder={field?.placeholder}
        value={value || formik?.values[field?.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        status={
          status
            ? status
            : formik.errors[field?.name] && formik.touched[field?.name]
            ? "error"
            : null
        }
        autoComplete="new-password"
        min={min}
        {...other}
      />
      <p className="error_txt">{formik.touched[field?.name] && formik.errors[field.name]}</p>
    </span>
  );
}

export default InputText;
