import { Button, Drawer, Space } from "antd";

function DrawerTable({ id, open, handleClose, data, ...rest }) {
  return (
    <Drawer
      title={`Drawer`}
      placement="right"
      onClose={() => handleClose(id)}
      open={open}
      width="35%"
      extra={
        <Space>
          <Button onClick={() => handleClose(id)}>Cancel</Button>
          <Button type="primary" onClick={() => handleClose(id)}>
            OK
          </Button>
        </Space>
      }
    >
      In Progress ...
    </Drawer>
  );
}

export default DrawerTable;
