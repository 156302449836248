import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  state: "idle",
  error: null,
  message: null
};

export const sendInvitations = createAsyncThunk("api/invitations", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/users/invite`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {},
  extraReducers: {
    //get operators

    //create op
    [sendInvitations.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [sendInvitations.fulfilled]: (state, action) => {
      const data = action.payload;
      console.log(data);
      state.state = "success";
    },
    [sendInvitations.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const {} = inviteSlice.actions;

export default inviteSlice.reducer;
