import Search from "antd/es/input/Search";
import React from "react";

function InputSearch({ field, onSearch, formik, ...rest }) {
  return (
    <span className="s_input">
      <label htmlFor={field.name}>{field.label}</label>
      <Search {...field} size="large" onChange={onSearch} {...rest} />
    </span>
  );
}

export default InputSearch;
