import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: [
    {
      id: "modal-example",
      open: false
    },
    {
      id: "user-modal",
      open: false
    },
    {
      id: "user-group",
      open: false
    },
    {
      id: "image-group",
      open: false
    },
    {
      id: "image-modal",
      open: false
    },
    {
      id: "user-group-modal",
      open: false
    },
    {
      id: "confirm-modal",
      open: false
    },
    {
      id: "template-modal",
      open: false
    },
    {
      id: "assign-modal",
      open: false
    },
    {
      id: "create-org",
      open: false
    },
    {
      id: "create-offer",
      open: false
    },
    {
      id: "invite-user",
      open: false
    },
    {
      id: "invite-user-modal",
      open: false
    },
    {
      id: "active-session-details",
      open: false
    },
    {
      id: "org-details-modal",
      open: false
    },
    {
      id: "invite-user-list-modal",
      open: false
    }
  ],
  drawers: [
    {
      id: "drawer-user",
      open: false
    },
    {
      id: "drawer-example",
      open: false
    },
    {
      id: "drawer-user-group",
      open: false
    },
    {
      id: "drawer-image",
      open: false
    },
    {
      id: "drawer-image-group",
      open: false
    },
    {
      id: "drawer-plan",
      open: false
    },
    {
      id: "create-drawer-plan",
      open: false
    }
  ]
};

const slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { id, data } = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = true;
      state.modals[index].data = data;
    },
    closeModal: (state, action) => {
      const id = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = false;
    },
    openDrawer: (state, action) => {
      const { id, data } = action.payload;
      const index = state.drawers.findIndex((modal) => modal.id === id);
      state.drawers[index].open = true;
      state.drawers[index].data = data;
    },
    closeDrawer: (state, action) => {
      const id = action.payload;
      const index = state.drawers.findIndex((modal) => modal.id === id);
      state.drawers[index].open = false;
    },
    closeAllModals: (state, action) => {
      state.drawers = initialState.drawers;
      state.modals = initialState.modals;
    }
  }
});

export const reducer = slice.reducer;

export const openModal = (id, data) => (dispatch) => {
  dispatch(slice.actions.openModal({ id, data }));
};

export const closeModal = (id) => (dispatch) => {
  dispatch(slice.actions.closeModal(id));
};

export const openDrawer = (id, data) => (dispatch) => {
  dispatch(slice.actions.openDrawer({ id, data }));
};

export const closeDrawer = (id) => (dispatch) => {
  dispatch(slice.actions.closeDrawer(id));
};

export const closeAllModals = (id) => (dispatch) => {
  dispatch(slice.actions.closeAllModals());
};

// export const openDrawer = () => (dispatch) => {
//   dispatch(slice.actions.openDrawer());
// };

// export const closeDrawer = () => (dispatch) => {
//   dispatch(slice.actions.closeDrawer());
// };

export default slice;
