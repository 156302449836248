import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  stats: null,
  resources: null,
  error: null,
  loading: null,
  userUsage: null
};

export const getStats = createAsyncThunk("api/stats/items/count", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/stats/items/count`, { params: query });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getResourcesUsage = createAsyncThunk(
  "api/stats/resources-usage",
  async ({ query }) => {
    let data;
    try {
      const response = await axiosInstance.get(`/stats/resources-usage`, { params: query });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getUsageUsers = createAsyncThunk(
  "api/stats/resources-usage/users",
  async ({ query }) => {
    let data;
    try {
      const response = await axiosInstance.get(`/stats/users/resources-usage`, { params: query });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const statisticSlice = createSlice({
  name: "statisticSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getStats.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getStats.fulfilled]: (state, action) => {
      state.stats = action.payload;
      state.loading = false;
    },
    [getStats.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getResourcesUsage.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getResourcesUsage.fulfilled]: (state, action) => {
      state.resources = action.payload;
      state.loading = false;
    },
    [getResourcesUsage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getUsageUsers.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getUsageUsers.fulfilled]: (state, action) => {
      state.userUsage = action.payload;
      state.loading = false;
    },
    [getUsageUsers.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    }
  }
});

// eslint-disable-next-line no-empty-pattern
export const {} = statisticSlice.actions;

export default statisticSlice.reducer;
